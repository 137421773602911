/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  TableCaption,
  Box,
} from "@chakra-ui/react";
import { MultiSelect } from "chakra-multiselect";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Table Components

// Data

// Icons
import { useLazyQuery } from "@apollo/client";
import {
  FETCH_ALL_TRANSACTIONS,
  FETCH_TRANSACTION_BY_ACTION,
  FETCH_TRANSACTION_BY_ADDRESS_ACTION,
  FETCH_TRANSACTION_BY_ADDRESS,
} from "utils/queries";
import TransactionsRow from "components/Tables/TransactionsRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { actionOptions } from "utils/constants";
import SearchBar from "components/SearchBar/SearchBar";

const NUM_TRANSACTIONS = 100;

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [option, setOption] = useState([]); // Filter by events
  const [skip, setSkip] = useState(0); // Skip state for pagination
  const [filterAddress, setFilterAddress] = useState(""); // Address filter
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true);

  // Fetch different sets of transactions based on the filters
  const [getTransactions] = useLazyQuery(FETCH_ALL_TRANSACTIONS);
  const [getTransactionByAction] = useLazyQuery(FETCH_TRANSACTION_BY_ACTION);
  const [getTransactionByAddressAndFilter] = useLazyQuery(
    FETCH_TRANSACTION_BY_ADDRESS_ACTION
  );
  const [getTransactionByAddress] = useLazyQuery(FETCH_TRANSACTION_BY_ADDRESS);

  // Helper function to update the transactions state based on currentSkip
  const updateTransactionsState = (data, currentSkip) => {
    if (data.transactions.length < NUM_TRANSACTIONS) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    if (currentSkip === 0) {
      setTransactions(data.transactions);
    } else {
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        ...data.transactions,
      ]);
    }
  };

  // Helper function to fetch transactions based on the current filters
  const fetchTransactions = async (currentSkip) => {
    setLoading(true); // Set loading to true before making the request

    const variables = {
      orderBy: "timestamp",
      orderDirection: "desc",
      skip: currentSkip,
      first: NUM_TRANSACTIONS,
    };

    try {
      let data;

      if (option.length > 0) {
        // Filter by events and possibly address
        if (filterAddress) {
          data = await getTransactionByAddressAndFilter({
            variables: {
              ...variables,
              address: filterAddress,
              actions: option,
            },
          });
        } else {
          data = await getTransactionByAction({
            variables: {
              ...variables,
              actions: option,
            },
          });
        }
      } else if (filterAddress) {
        // Filter by address only
        data = await getTransactionByAddress({
          variables: {
            ...variables,
            address: filterAddress,
          },
        });
      } else {
        // Fetch all transactions without filters
        data = await getTransactions({
          variables,
        });
      }

      // Update the state based on the response and currentSkip
      updateTransactionsState(data.data, currentSkip);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  // Fetch the next batch of transactions when 'Load More' is clicked
  const loadMore = () => {
    const newSkip = skip + NUM_TRANSACTIONS;
    setSkip(newSkip); // Update the skip state
    fetchTransactions(newSkip); // Fetch the next batch
  };

  // Reset data and refetch transactions when filters change
  useEffect(() => {
    setSkip(0); // Reset skip
    fetchTransactions(0); // Fetch transactions with the current filters
  }, [option, filterAddress]); // Re-run when filters change

  // Function to reset transactions when necessary (no filters)
  const resetData = () => {
    setSkip(0);
    getTransactions({
      variables: {
        orderBy: "timestamp",
        orderDirection: "desc",
        first: NUM_TRANSACTIONS,
        skip: 0,
      },
    }).then((response) => {
      const { data } = response;
      setTransactions(data?.transactions);
    });
  };

  const setSearchBarData = (data) => {
    setTransactions(data);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {/* Authors Table */}
      <Card pb="0px" className="scroll-content">
        <CardHeader p="6px 0px 22px 0px" w="100%" flexDirection="column">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Transactions
          </Text>
          <Flex justifyContent="space-between" w="100%" mt="30px">
            <SearchBar
              setSearchBarData={setSearchBarData}
              resetData={resetData}
              parent="transactions"
              selectedActions={option}
              setFilterAddress={setFilterAddress}
            />

            <Box className="custom-multi-select">
              <MultiSelect
                options={actionOptions}
                value={option}
                onChange={setOption}
                placeholder="Filter by Event"
                size="md"
                multiple
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff" flexDirection="column">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={() => loadMore()}
                isLoading={loading}
                isDisabled={!hasMore}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Event
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Address
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Transaction Hash
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Dynasty
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Timestamp
                </Th>
                {/* <Th borderBottomColor="#56577A"></Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {loading && transactions.length === 0 ? (
                <SkeletonTable rowCount={10} />
              ) : transactions.length > 0 ? (
                transactions.map((row, index) => {
                  return <TransactionsRow transaction={row} key={row.id} />;
                })
              ) : (
                <TransactionsRow transaction={transactions} key={0} />
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Transactions;
