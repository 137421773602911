import DelegationDrawer from "./DelegationDrawer";
import InitiateWithdrawDrawer from "./InitiateWithdrawDrawer";
import ResetLockDrawer from "./ResetLockDrawer";
import SignalWithdrawDrawer from "./SignalWithdrawDrawer";
import WithdrawDrawer from "./WithdrawDrawer";

const ActionDrawer = ({
  isOpen,
  onClose,
  validatorId,
  validatorAddress,
  toggleDrawer,
  actionType,
  signerAddressData,
  unstakeLock,
  withdrawLock,
}) => {
  return (
    <>
      {actionType === "delegate" && (
        <DelegationDrawer
          isOpen={isOpen}
          onClose={onClose}
          validatorAddress={validatorAddress}
          toggleDrawer={toggleDrawer}
          validatorId={validatorId}
          signerAddressData={signerAddressData}
        />
      )}
      {actionType === "signalWithdraw" && (
        <SignalWithdrawDrawer
          isOpen={isOpen}
          onClose={onClose}
          validatorAddress={validatorAddress}
          toggleDrawer={toggleDrawer}
          validatorId={validatorId}
          signerAddressData={signerAddressData}
        />
      )}
      {actionType === "initiateWithdraw" && (
        <InitiateWithdrawDrawer
          isOpen={isOpen}
          onClose={onClose}
          validatorAddress={validatorAddress}
          toggleDrawer={toggleDrawer}
          validatorId={validatorId}
          signerAddressData={signerAddressData}
          unstakeLock={unstakeLock}
        />
      )}
      {actionType === "withdraw" && (
        <WithdrawDrawer
          isOpen={isOpen}
          onClose={onClose}
          validatorAddress={validatorAddress}
          toggleDrawer={toggleDrawer}
          validatorId={validatorId}
          signerAddressData={signerAddressData}
          withdrawLock={withdrawLock}
        />
      )}
      {actionType === "resetLock" && (
        <ResetLockDrawer
          isOpen={isOpen}
          onClose={onClose}
          validatorAddress={validatorAddress}
          toggleDrawer={toggleDrawer}
          validatorId={validatorId}
          signerAddressData={signerAddressData}
          unstakeLock={unstakeLock}
        />
      )}
    </>
  );
};

export default ActionDrawer;
