import { useEffect } from "react";

// Chakra imports
import { Flex } from "@chakra-ui/react";

// Custom components

// Table Components

// Data

// Icons
import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FETCH_VALIDATOR_BY_ADDRESS } from "utils/queries";
import CommitTable from "./CommitTable";
import Header from "./Header";
import RevealTable from "./RevealTable";

const RequestDetails = () => {
  const [validatorsData, setValidatorsData] = useState([]);
  const { validatorAddress } = useParams();
  const [getValidatorData, { data: signerAddressData }] = useLazyQuery(
    FETCH_VALIDATOR_BY_ADDRESS,
    {
      variables: {
        address: validatorAddress,
      },
    }
  );
  useEffect(() => {
    fetchValidatorInfo();
  }, [validatorAddress]);

  useEffect(() => {
    signerAddressData?.validators &&
      setValidatorsData(signerAddressData?.validators);
  }, [signerAddressData]);

  const fetchValidatorInfo = async () => {
    await getValidatorData();
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Header />
      <CommitTable />
      <RevealTable />
    </Flex>
  );
};

export default RequestDetails;
