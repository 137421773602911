import {
  Box,
  Button,
  Code,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Stack,
  Tag,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import Blockies from "react-blockies";
import useMode from "../../hooks/useMode";
import {
  getETA,
  getLatestBlockTimestamp,
  resetSignalLock,
} from "../../utils/contracts";
import { epochMode } from "../../utils/constants";

const ResetLockDrawer = ({
  isOpen,
  onClose,
  validatorId,
  validatorAddress,
  toggleDrawer,
  unstakeLock,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(null);
  const firstField = useRef();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { mode } = useMode();
  const toast = useToast();

  const fetchTimestamp = async () => {
    const latestTimestamp = await getLatestBlockTimestamp();
    setTimestamp(latestTimestamp);
  };
  const { amount, unlockAfter, unlockBefore } = unstakeLock;

  useEffect(() => {
    fetchTimestamp();
  }, []);

  const handleRPCError = (error) => {
    return <Code colorScheme="black">{error.message}</Code>;
  };

  const triggerToast = (title, status = "success", description = null) => {
    toast({
      title,
      description,
      status,
      isClosable: true,
      duration: 5000,
    });
  };

  const resetLock = async () => {
    setIsLoading(true);

    const { success, txHash, error } = await resetSignalLock(validatorId);
    setIsLoading(false);
    console.log({
      success,
      txHash,
      error,
    });
    if (success) {
      triggerToast("Reset Signal Lock transaction successful", "success", null);
      toggleDrawer();
    } else {
      triggerToast(
        "Reset Signal Lock transaction failed",
        "error",
        error?.message && handleRPCError(error)
      );
    }
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        initialFocusRef={firstField}
        onClose={onClose}
        placement={isMobile ? "bottom" : "right"}
      >
        <DrawerOverlay />
        <DrawerContent bg="linear-gradient(111.84deg, rgba(0, 0, 0, 0.94) 59.3%, rgba(26, 31, 55, 0) 100%)">
          <DrawerHeader borderBottomWidth="1px">
            <Tag>Reset Signal Lock</Tag>
            <Flex>
              <Blockies seed={validatorAddress} className="indenticon" />
              <Text>{validatorAddress.slice(0, 20)}</Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing="24px">
              <Box>
                <Stack
                  //   divider={<StackDivider />}
                  spacing="4"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      Amount
                    </Heading>
                    <Text pt="2" fontSize="sm">
                      {(+ethers.utils.formatEther(amount)).toFixed(3)}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      State
                    </Heading>
                    <Text pt="2" fontSize="sm">
                      {mode === -1 ? "-" : epochMode[mode]}
                    </Text>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Stack
                  //   divider={<StackDivider />}
                  spacing="4"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      Initiate After
                    </Heading>
                    <Text pt="2" fontSize="sm">
                      {unlockAfter.toNumber()}{" "}
                      {`(${getETA(
                        timestamp,
                        unlockAfter,
                        unlockBefore,
                        true
                      )})`}
                    </Text>
                  </Box>
                  <Box>
                    <Heading
                      size="xs"
                      textTransform="uppercase"
                      textAlign="end"
                    >
                      Initiate Before
                    </Heading>
                    <Text pt="2" fontSize="sm" textAlign="end">
                      {unlockBefore.toNumber()}{" "}
                      {`(${getETA(
                        timestamp,
                        unlockAfter,
                        unlockBefore,
                        false
                      )})`}
                    </Text>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={toggleDrawer}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={resetLock}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Confirm
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ResetLockDrawer;
