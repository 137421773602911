import { ethers } from "ethers";
import { convertHexToDecimal } from "./oracleDecoder";

export const RPC_URL = "https://rpc.metalayer.net/";

export const epochMode = [
  "Initialize",
  "ValidatorSelection",
  "SignerCreation",
  "Signing",
];

export const modes = {
  Initialize: 0,
  ValidatorSelection: 1,
  SignerCreation: 2,
  Signing: 3,
};

export const razorSchain = {
  id: 1444673419,
  name: "Europa Testnet",
  network: "juicy-low-small-testnet",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Skale",
    symbol: "sFuel",
  },
  rpcUrls: {
    default: ["https://testnet.skalenodes.com/v1/juicy-low-small-testnet"],
  },
  blockExplorers: {
    default: {
      name: "Skalenodes",
      url: "https://juicy-low-small-testnet.explorer.testnet.skalenodes.com/",
    },
  },
  testnet: true,
};

export const opSepoliaTestnet = {
  id: 11155420,
  name: "OP Sepolia Testnet",
  network: "op-sepolia-testnet",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    public: {
      http: ["https://sepolia-optimism.etherscan.io/"],
    },
    default: {
      http: ["https://sepolia-optimism.etherscan.io/"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Etherscan",
      url: "https://sepolia-optimism.etherscan.io/",
    },
    default: {
      name: "Etherscan",
      url: "https://sepolia-optimism.etherscan.io/",
    },
  },
};

export const privateGeth = {
  id: 837567383277,
  name: "Private Geth",
  network: "private-geth",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.metalayer.xyz"],
    },
  },
  blockExplorers: {
    default: {
      name: "Blockscout explorer",
      url: "https://explorer.metalayer.net/",
    },
  },
  testnet: true,
};

export const metalayerHoleskyNetwork = {
  id: 7777123123,
  name: "Metalayer Holesky Network",
  network: "metalayer-holesky",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.metalayer.net/"],
    },
  },
  blockExplorers: {
    default: {
      name: "Blockscout explorer",
      url: "https://explorer.metalayer.net/",
    },
  },
  testnet: true,
};

export const getBlockExplorerUrl = () =>
  metalayerHoleskyNetwork.blockExplorers.default.url;

export const zeroBigNumber = ethers.BigNumber.from(0);

export const getDecodedCollectionResult = (data) => {
  const CollectionResultABI = [
    "int8 power",
    "uint16 collectionId",
    "bytes32 name",
    "uint256 value",
    "uint256 lastUpdatedTimestamp",
  ];

  try {
    const abiCoder = new ethers.utils.AbiCoder();

    const decodedCollectionResult = ethers.utils.defaultAbiCoder.decode(
      CollectionResultABI,
      data
    );
    return decodedCollectionResult;
  } catch (error) {
    console.log("Error while decoding", error);
    return null;
  }
};

export const decodeBlockData = (requests, messageData, requestIds) => {
  const abiCoder = new ethers.utils.AbiCoder();
  const BlockDataABI = ["uint256 epoch", "bytes32 merkleRoot"];

  const { epoch, merkleRoot } = abiCoder.decode(BlockDataABI, messageData);

  const decodedRequestValues = [];
  requests.map((request) => {
    console.log({ request });
    let {
      id,
      resultDestinationChainDomain,
      resultRequestingChainDomain,
      resultTarget,
      resultValues,
    } = request;

    let decodedReqValue;

    // * values in result contains => [message, result]
    // * here message is same as mentioned in request
    // * result contains exec result, oracle aggregated

    let decodedMessage, message, result;
    try {
      // decodedMessage = abiCoder.decode(["bytes[]"], resultValues);
      decodedMessage = abiCoder.decode(["bytes", "bytes"], resultValues);
      // decodedMessage = decodedMessage[0];
      message = decodedMessage[0];
      result = decodedMessage[1];
    } catch (error) {
      console.log(error);
      return;
    }

    // * decoding of oracle(aggregation) request
    try {
      const decoded = convertHexToDecimal(result);
      if (decoded === null) {
        console.log("Skipping request due to conversion error.");
      }
      decodedReqValue = decoded; // Assign the decoded value
      if (decoded !== null) {
        decodedRequestValues.push({
          requestType: "oracle",
          requestId: id,
          power: 0,
          collectionId: 0,
          collectionNameHash: "",
          result: `${decodedReqValue}`,
          requestingChainDomain: resultRequestingChainDomain,
          destinationChainDomain: resultDestinationChainDomain,
          message: `${ethers.utils.toUtf8String(message)}`,
        });
      }
    } catch (error) {
      console.log("Error decoding value:", error);
      return; // Continue to the next iteration of the loop
    }

    if (decodedReqValue) {
      return;
    }

    // * decoding of attest/exec request
    // * if message === "0x" it's attest request else exec request

    if (result === "0x") {
      const decodedCollectionResult = getDecodedCollectionResult(message);
      if (decodedCollectionResult) {
        decodedRequestValues.push({
          requestType: "attest",
          requestId: id,
          power: decodedCollectionResult.power,
          collectionId: decodedCollectionResult.collectionId,
          collectionNameHash: decodedCollectionResult.name,
          result: decodedCollectionResult.value.toNumber(),
          requestingChainDomain: resultRequestingChainDomain,
          destinationChainDomain: resultDestinationChainDomain,
          message: "",
        });
      }
    } else {
      decodedRequestValues.push({
        requestType: "exec",
        requestId: id,
        power: 0,
        collectionId: 0,
        collectionNameHash: "",
        result: ethers.utils.toUtf8String(result),
        requestingChainDomain: resultRequestingChainDomain,
        destinationChainDomain: resultDestinationChainDomain,
        message: `${ethers.utils.toUtf8String(message)}`,
      });
    }
  });

  return {
    epoch,
    requestIds,
    values: decodedRequestValues,
  };
};

export const decodeMessageData = (data) => {
  const abiCoder = new ethers.utils.AbiCoder();
  const decodedRequestValues = [];
  try {
    const BlockDataABI = [
      "uint256 epoch",
      "bytes4[] requestIds",
      "(uint256 requestingChainDomain,uint256 destinationChainDomain,address target,bytes values)[] requestValues",
    ];

    const CollectionResultABI = [
      "tuple(int8 power, uint16 collectionId, bytes32 name, uint256 value)",
    ];

    const { epoch, requestIds, requestValues } = abiCoder.decode(
      BlockDataABI,
      data
    );

    requestValues.map((reqValue, i) => {
      const [
        requestingChainDomain,
        destinationChainDomain,
        target,
        value,
      ] = reqValue;

      const reqValueABI = ["address sender", "bytes[] results"];

      const decodedReqValue = abiCoder.decode(reqValueABI, value);
      const { sender, results } = decodedReqValue;
      const [requestMessageData, oracleOrExecResult] = results;

      // * if oracleOrExecResult === "0x" it's attest request else oracle/exec request

      if (oracleOrExecResult === "0x") {
        const decodedCollectionResult = getDecodedCollectionResult(
          requestMessageData
        );
        if (decodedCollectionResult) {
          decodedRequestValues.push({
            requestType: "attest",
            requestId: requestIds[i],
            power: decodedCollectionResult.power,
            collectionId: decodedCollectionResult.collectionId,
            collectionNameHash: decodedCollectionResult.name,
            result: decodedCollectionResult.value.toNumber(),
            requestingChainDomain: requestingChainDomain.toNumber(),
            destinationChainDomain: destinationChainDomain.toNumber(),
            message: "",
          });
        }
      } else {
        decodedRequestValues.push({
          requestType: "exec",
          requestId: requestIds[i],
          power: 0,
          collectionId: 0,
          collectionNameHash: "",
          result: 0,
          requestingChainDomain: requestingChainDomain.toNumber(),
          destinationChainDomain: destinationChainDomain.toNumber(),
          message: `${ethers.utils.toUtf8String(
            requestMessageData
          )} - ${ethers.utils.toUtf8String(oracleOrExecResult)}`,
        });
      }
    });

    return {
      epoch,
      requestIds,
      values: decodedRequestValues,
    };
  } catch (e) {
    console.log("Error decoding message", e);
  }
};

export const actionOptions = [
  {
    label: "Created Request",
    value: "CreatedRequest",
  },
  {
    label: "Validator Selection",
    value: "ValidatorSelection",
  },
  {
    label: "Validators ChurnedOut",
    value: "ValidatorsChurnedOut",
  },
  {
    label: "Validator Jailed",
    value: "ValidatorJailed",
  },
  {
    label: "Attest Signer",
    value: "AttestSigner",
  },
  {
    label: "Confirm Transfer",
    value: "ConfirmTransfer",
  },
  {
    label: "Request Committed",
    value: "RequestCommitted",
  },
  {
    label: "Request Status Changed",
    value: "RequestStatusChanged",
  },
  {
    label: "Request Revealed",
    value: "RequestRevealed",
  },
  {
    label: "Validator Snitched",
    value: "ValidatorSnitched",
  },
  {
    label: "Finalize Block",
    value: "FinalizeBlock",
  },
  {
    label: "Attest Blame",
    value: "AttestBlame",
  },
  {
    label: "Slashed",
    value: "Slashed",
  },
  {
    label: "Stake Change",
    value: "StakeChange",
  },
  // {
  //   label: "Unstaked",
  //   value: "Unstaked",
  // },
  // {
  //   label: "Withdraw",
  //   value: "Withdraw",
  // },
  {
    label: "Delegated",
    value: "Delegated",
  },
  {
    label: "Quit Network",
    value: "QuitNetwork",
  },
  {
    label: "Withdraw Signalled",
    value: "WithdrawSignalled",
  },
  {
    label: "Withdraw Initiated",
    value: "WithdrawInitiated",
  },
  {
    label: "Withdraw Executed",
    value: "WithdrawExecuted",
  },
  {
    label: "Reset Signal Lock",
    value: "ResetSignalLock",
  },

  // {
  //   label: "Undelegate",
  //   value: "UndelegationSignalled",
  // },
  // {
  //   label: "Undelegation Initiated",
  //   value: "UndelegationInitiated",
  // },
  // {
  //   label: "Undelegation Executed",
  //   value: "UndelegationExecuted",
  // },
  {
    label: "Blame Consensus",
    value: "BlameConsensus",
  },
  {
    label: "Chain Status Changed",
    value: "ChainStatusChanged",
  },
  {
    label: "Bridge Recovery",
    value: "BridgeRecovery",
  },
  {
    label: "Block Dispute Reached",
    value: "BlockDisputeReached",
  },
  {
    label: "Commission Updated",
    value: "CommissionUpdated",
  },
  {
    label: "Delegation Acceptance Changed",
    value: "DelegationAcceptanceChanged",
  },
  {
    label: "Escape Hatch",
    value: "EscapeHatch",
  },
  {
    label: "Genesis Reset Timestamp Set",
    value: "GenesisResetTimestampSet",
  },
  {
    label: "Message Consensus Reached",
    value: "MessageConsensusReached",
  },
  {
    label: "Signer Consensus Reached",
    value: "SignerConsensusReached",
  },
  {
    label: "Signer Proof Dispute Reached",
    value: "SignerProofDisputeReached",
  },
];

export const requestStatusOptions = [
  {
    label: "Commit",
    value: "Commit",
  },
  {
    label: "Reveal",
    value: "Reveal",
  },
  {
    label: "Signing",
    value: "Signing",
  },
  {
    label: "Finalized",
    value: "Finalized",
  },
];

export const modeFetchInterval = 15000;

export const collectionHashToName = {
  "0x59102b37de83bdda9f38ac8254e596f0d9ac61d2035c07936675e87342817160":
    "ETHUSD",
  "0x1bbf634c3ad0a99dd58667a617f7773ccb7f37901afa8e9ea1e32212bddb83c9":
    "ethCollectionMedian",
  "0x67bd3ff97bf177a0656f963f0ab4b7f79380cca36175874421bda3d16367eb56":
    "RAZORUSD",
  "0x585e50304d7617513ba522ae7a4953ad43f9f9c1f14e27226541dc8bcb98d3ed":
    "UFTUSD",
  "0x7416a56f222e196d0487dce8a1a8003936862e7a15092a91898d69fa8bce290c":
    "USDCUSD",
  "0x6dcd0a8fb0460d4f0f98c524e06c10c63377cd098b589c0b90314bfb55751558":
    "USDTUSD",
  "0xd5b67be12fa25da57dfa9e287e2288a1e4be5e5f7d85e09178ea507d602485c4":
    "MATICUSD",
  "0xabd18015a48c1375c4c6b18f5ac2b10556bfac3597d878bea4f9599a34270cb2":
    "GRTUSD",
  "0x1dcd89dfded9e8a9b0fa1745a8ebbacbb7c81e33d5abc81616633206d932e837":
    "SOLUSD",
  "0x8e5cdbd3b44a262e8d89f4f9265bb151bd57c229edb0e5f012c110cdeb50b08d":
    "SKALEUSD",
  "0x365f8b30df22d80f2e0b406679ef53f75e49d6cad9c1c9e6bc1df7b092658a20":
    "ARBUSD",
  "0x7404e3d104ea7841c3d9e6fd20adfe99b4ad586bc08d8f3bd3afef894cf184de":
    "BTCUSD",
  "0x0c26b653bce6116d26804ec1e9815465af19aaf56c39eaaf001816375fdf8a1d":
    "AVAXUSD",
  "0xbd6574dc5978208b7bee561009bf3ea06d4086e2886c183854983174379b5cf0":
    "GLMRUSD",
  "0xfcc0e3944c283290cebf2f675246fa8edf52a1bff1350145b81d8a55099fa71b":
    "XDCUSD",
  "0x2316c275dd635566dd979f717711dcb9fef72e5b70bb46b28ed03958eb7a0e85": "OPUSD",
};

export const chainIDToName = {
  1: "Ethereum Mainnet",
  3: "Ropsten Testnet",
  4: "Rinkeby Testnet",
  5: "Goerli Testnet",
  42: "Kovan Testnet",
  56: "BNB Smart Chain Mainnet",
  97: "BNB Smart Chain Testnet",
  137: "Polygon Mainnet",
  80001: "Mumbai Testnet",
  17000: "Holesky",
  10: "Optimism Mainnet",
  69: "Optimism Kovan Testnet",
  42161: "Arbitrum One Mainnet",
  421611: "Arbitrum Rinkeby Testnet",
  100: "Gnosis Chain",
  43114: "Avalanche C-Chain Mainnet",
  43113: "Avalanche Fuji Testnet",
  250: "Fantom Opera",
  4002: "Fantom Testnet",
  82: "Meter Mainnet",
  83: "Meter Testnet",
  66: "OKXChain Mainnet",
  65: "OKExChain Testnet",
  1029: "Cronos Mainnet",
  338: "Cronos Testnet",
  1285: "Moonriver",
  1287: "Moonbase Alpha",
  122: "Fuse Mainnet",
  123: "Fuse Sparknet",
  25: "Cronos",
  61: "Ethereum Classic Mainnet",
  63: "Ethereum Classic Testnet Mordor",
  11297108109: "Palm",
  421614: "Arbitrum Sepolia",
  11155111: "Sepolia",
  1444673419: "Europa Testnet",
};
