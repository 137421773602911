import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import MetalayerTokenABI from "@razor-network/bridge-contracts-snapshot/abi/MetalayerToken.json";

import { ethers } from "ethers";
import ADDRESSES from "@razor-network/bridge-contracts-snapshot/deployments/info/testnet/native.json";

const useTokenBalance = () => {
  const [balance, setBalance] = useState(0);
  const { address } = useAccount();
  const fetchTokenBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const tokenContract = new ethers.Contract(
        ADDRESSES[7777123123].MetalayerToken,
        MetalayerTokenABI,
        provider
      );
      const balance = await tokenContract.balanceOf(address);
      setBalance(balance);
    } catch (error) {
      console.log("Error occured while fetching token balance", error);
    }
  };

  useEffect(() => {
    if (address) {
      fetchTokenBalance();
    } else {
      setBalance(0);
    }
  }, [address]);

  return { balance };
};

export default useTokenBalance;
