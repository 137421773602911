/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// Chakra imports
import {
  Button,
  Flex,
  Table,
  TableCaption,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { useLazyQuery } from "@apollo/client";
import RevealEventRow from "components/Tables/RevealEventRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { useParams } from "react-router-dom";
import { FETCH_REVEALED_EVENTS_BY_REQUEST_ID } from "utils/queries";

const NUM_TRANSACTIONS = 50;

const RevealTable = () => {
  const [revealedEvents, setRevealedEvents] = useState([]);
  const { requestId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [
    getRevealedEventsByRequestId,
    { data: revealedData, loading },
  ] = useLazyQuery(FETCH_REVEALED_EVENTS_BY_REQUEST_ID, {
    variables: {
      requestId,
      orderBy: `timestamp`,
      orderDirection: `desc`,
      skip: skip,
      first: NUM_TRANSACTIONS,
    },
  });

  const fetchAllRevealedEvents = async () => {
    setIsLoading(true);
    await getRevealedEventsByRequestId();
    setIsLoading(false);
  };

  useEffect(() => {
    requestId && fetchAllRevealedEvents();
  }, [requestId]);

  useEffect(() => {
    if (revealedData?.requestRevealedEvents) {
      if (revealedData.requestRevealedEvents.length < NUM_TRANSACTIONS) {
        setHasMore(false);
      }
      setRevealedEvents((revealedEvents) => [
        ...revealedEvents,
        ...revealedData.requestRevealedEvents,
      ]);
    }
  }, [revealedData]);

  return (
    <Flex direction="column" mt={8}>
      {/* Authors Table */}
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Request Revealed Details
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff" flexDirection="column">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={() => setSkip(skip + NUM_TRANSACTIONS)}
                isLoading={loading}
                isDisabled={!hasMore}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Validator
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Dynasty
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Timestamp
                </Th>

                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Transaction Hash
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Requesting Chain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Destination Chain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Values
                </Th>
                {/* <Th borderBottomColor="#56577A"></Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <SkeletonTable rowCount={10} />
              ) : revealedEvents.length === 0 ? (
                <RevealEventRow key={0} revealedEvent={revealedEvents} />
              ) : (
                revealedEvents.map((row) => (
                  <RevealEventRow key={row.id} revealedEvent={row} />
                ))
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default RevealTable;
