import { Button, Flex, Link, Text, useClipboard } from "@chakra-ui/react";
import { useState } from "react";
import Blockies from "react-blockies";
import { IoCopyOutline, IoCheckmarkDoneSharp } from "react-icons/io5";

const Address = ({
  address,
  allowRedirection = true,
  ellipsis = true,
  justifyContentCenter = false,
  blockExplorer = false,
  isExternal = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const { onCopy } = useClipboard(address);

  const handleCopy = () => {
    onCopy();
    setIsCopied(true);
    // Reset the copied state after a delay
    setTimeout(() => setIsCopied(false), 2000);
  };

  return allowRedirection ? (
    <Flex alignItems="center" justifyContent="center">
      <Link
        href={
          allowRedirection && blockExplorer
            ? `${process.env.REACT_APP_BLOCK_EXPLORER}address/${address}/`
            : allowRedirection
            ? `/validators/${address}`
            : ""
        }
        display="flex"
        alignItems="center"
        justifyContent={justifyContentCenter ? "center" : ""}
        isExternal={isExternal}
        fontWeight="bold"
      >
        <Blockies seed={address} className="indenticon" />
        <Text className={ellipsis ? "custom-ellipsis" : ""}>{address}</Text>
      </Link>
      <Button onClick={handleCopy} padding={0} colorScheme="gray" ml={2}>
        {isCopied ? <IoCheckmarkDoneSharp /> : <IoCopyOutline />}
      </Button>
    </Flex>
  ) : (
    <Flex alignItems="center" justifyContent="center">
      <Blockies seed={address} className="indenticon" />
      <Text
        className={ellipsis ? "custom-ellipsis" : ""}
        title={address}
        color="#fff"
        fontWeight="bold"
      >
        {address}
      </Text>
      <Button onClick={handleCopy} padding={0} colorScheme="gray" ml={2}>
        {isCopied ? <IoCheckmarkDoneSharp /> : <IoCopyOutline />}
      </Button>
    </Flex>
  );
};

export default Address;
