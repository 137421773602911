import { Box, Skeleton, HStack } from "@chakra-ui/react";

function TransactionsSkeletonData() {
  const skeletonRows = Array(6).fill(null); // Array to generate 6 rows

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" width="100%">
      {skeletonRows.map((_, index) => (
        <HStack spacing={4} mt={index === 0 ? 0 : 6} key={index}>
          <Skeleton height="20px" width="25%" />
          <Skeleton height="20px" width="75%" />
        </HStack>
      ))}
    </Box>
  );
}

export default TransactionsSkeletonData;
