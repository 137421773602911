import React, { useEffect } from "react";

// Chakra imports
import { Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Table Components

// Data

// Icons
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { FETCH_ACTIVE_SET_VALIDATORS } from "utils/queries";
import { useParams } from "react-router-dom";
import SkeletonTable from "components/Tables/SkeletonTable";
import ActiveSetRow from "./ActiveSetRow";

const ActiveSetTable = () => {
  const [validatorsData, setValidatorsData] = useState([]);
  const { validatorAddress } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [getValidators, { data }] = useLazyQuery(FETCH_ACTIVE_SET_VALIDATORS, {
    variables: {
      orderBy: `id`,
      orderDirection: `asc`,
      isChurnedOut: false,
    },
  });

  useEffect(() => {
    fetchAllValidators();
    console.log(validatorAddress);
    return () => {};
  }, []);

  useEffect(() => {
    data?.validators && setValidatorsData(data.validators);
  }, [data]);

  const fetchAllValidators = async () => {
    setIsLoading(true);
    await getValidators();
    setIsLoading(false);
  };

  return (
    <Card
      overflowX={{ sm: "scroll", xl: "hidden" }}
      pb="0px"
      className="scroll-content"
    >
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="lg" color="#fff" fontWeight="bold">
          Active Set
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color="#fff">
          <Thead>
            <Tr my=".8rem" ps="0px" color="gray.400">
              <Th
                ps="0px"
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Validator
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Stake
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Status
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <SkeletonTable rowCount={10} />
            ) : validatorsData.length === 0 ? (
              <ActiveSetRow
                address=""
                stake={0}
                validatorId={0}
                status={true}
                lastItem={true}
              />
            ) : (
              validatorsData.map((row, index, arr) => {
                return (
                  <ActiveSetRow
                    key={index}
                    address={row.address}
                    stake={row.stake / 1e18}
                    validatorId={row.validatorId}
                    status={row.isChurnedOut}
                    lastItem={index === arr.length - 1 ? true : false}
                  />
                );
              })
            )}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ActiveSetTable;
