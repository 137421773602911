import Decimal from "decimal.js";
import { Buffer } from "buffer";

export function convertHexToDecimal(hexString) {
  // Remove the '0x' prefix if present
  if (hexString.startsWith("0x")) {
    hexString = hexString.slice(2);
  }

  // Convert hex string to a Buffer (byte array)
  const byteArray = Buffer.from(hexString, "hex");

  // Convert bytes to string
  const strValue = byteArray.toString("utf8");

  // Check if strValue is empty or invalid
  if (!strValue) {
    return null; // Return null in case of error
  }

  // Parse the string to a Decimal object
  try {
    const decimalValue = new Decimal(strValue);
    return decimalValue; // Return the valid decimal value
  } catch (error) {
    console.error("Error parsing decimal:", error);
    return null; // Return null in case of error
  }
}
