import { useEffect, useState } from "react";
import { getMode } from "utils/contracts";
import { modeFetchInterval } from "../utils/constants";

const useMode = () => {
  const [mode, setMode] = useState(-1);

  const fetchMode = async () => {
    const currentMode = await getMode();
    setMode(currentMode);
  };

  useEffect(() => {
    fetchMode();
    const interval = setInterval(() => fetchMode(), [modeFetchInterval]);
    return () => clearInterval(interval);
  }, []);

  return { mode };
};

export default useMode;
