import {
  Badge,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Address from "components/Address/Address";
import moment from "moment/moment";
import React from "react";
import { getTimeFromNow } from "utils";

function TransactionsRow({ transaction }) {
  const {
    epoch,
    dynasty,
    action,
    address,
    transactionHash,
    timestamp,
  } = transaction;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps="0px"
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {/* <Icon as={logo} h={"20px"} w={"20px"} me='18px' /> */}
          <Text fontSize="sm" color="#fff" fontWeight="bold" minWidth="100%">
            {action ?? "-"}
          </Text>
        </Flex>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          {address ? <Address address={address} /> : "-"}
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          {transactionHash ? (
            <Link to={transactionHash}>{transactionHash}</Link>
          ) : (
            "-"
          )}
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Flex direction="column">
          <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
            {epoch ?? "-"}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {dynasty ?? "-"}
        </Text>
      </Td>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {timestamp ? getTimeFromNow(timestamp) : "-"}
        </Text>
      </Td>
    </Tr>
  );
}

export default TransactionsRow;
