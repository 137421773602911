import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Code,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";

const ResultDetails = ({ result, isLoading }) => {
  // TODO: add decoding of request result data

  return (
    <Card
      bg="linear-gradient(127.09deg, rgba(0, 0, 0, 0.94) 19.41%, rgb(10 10 15 / 49%) 76.65%)"
      mt={8}
      mb={8}
    >
      <CardHeader>
        <Heading size="md">Result Details</Heading>
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <SimpleGrid columns={2} spacing={4}>
            <Box>
              <Heading size="sm" color="gray.400">
                Requesting Chain
              </Heading>
              {isLoading ? (
                <Skeleton
                  height="30px"
                  borderRadius={"5px"}
                  width="200px"
                ></Skeleton>
              ) : (
                <Text pt="2" fontSize="md">
                  {result?.requestingChain || "-"}
                </Text>
              )}

              {/* {result ? (
                <Text pt="2" fontSize="md">
                  {result.requestingChain || "-"}
                </Text>
              ) : (
                <Skeleton
                  mt={2}
                  height="30px"
                  borderRadius={"5px"}
                  width="200px"
                ></Skeleton>
              )} */}
              <Text pt="2" fontSize="md"></Text>
            </Box>
            <Box>
              <Heading size="sm" color="gray.400">
                Destination Chain
              </Heading>
              {isLoading ? (
                <Skeleton
                  height="30px"
                  borderRadius={"5px"}
                  width="200px"
                ></Skeleton>
              ) : (
                <Text pt="2" fontSize="md">
                  {result?.destinationChain || "-"}
                </Text>
              )}
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4}>
            <Box>
              <Heading size="sm" color="gray.400">
                Target Contract
              </Heading>
              {isLoading ? (
                <Skeleton
                  height="30px"
                  borderRadius={"5px"}
                  width="200px"
                ></Skeleton>
              ) : (
                <Text pt="2" fontSize="md">
                  {result?.target || "-"}
                </Text>
              )}
            </Box>
            <Box>
              <Heading size="sm" color="gray.400">
                Result
              </Heading>
              {isLoading ? (
                <Skeleton
                  height="30px"
                  borderRadius={"5px"}
                  width="200px"
                ></Skeleton>
              ) : result?.result ? (
                <Code mt="2" maxWidth="100%">
                  {result.result}
                </Code>
              ) : (
                <Text pt="2" fontSize="md">
                  {"-"}
                </Text>
              )}
              {/* {result ? (
                result.result ? (
                  <Code mt="2" maxWidth="100%">
                    {result.result}
                  </Code>
                ) : (
                  <Text pt="2" fontSize="md">
                    {result.result || "-"}
                  </Text>
                )
              ) : (
                <Skeleton
                  mt={2}
                  height="30px"
                  borderRadius={"5px"}
                  width="200px"
                ></Skeleton>
              )} */}
            </Box>
          </SimpleGrid>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ResultDetails;
