import {
  Flex,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

// Styles for the circular progressbar
// Custom components
import CardBody from "components/Card/CardBody.js";
// Icons

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FETCH_VALIDATOR_BY_ADDRESS } from "utils/queries";
import { useLazyQuery } from "@apollo/client";
import { CloseIcon } from "@chakra-ui/icons";
import ContractActions from "components/ContractActions";
import Card from "components/Card/Card";
import { getMode } from "utils/contracts";
import { getEpoch } from "utils/contracts";
import { getDynasty } from "utils/contracts";
import { epochMode } from "utils/constants";
import NumberWithTooltip from "components/Text/NumberWithTooltip";
import Address from "components/Address/Address";

const Header = () => {
  const [validatorsData, setValidatorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEpochLoading, setIsEpochLoading] = useState(false);
  const [headerData, setHeaderData] = useState({
    mode: null,
    epoch: null,
    dynasty: null,
  });
  const { validatorAddress } = useParams();
  const [getValidatorData, { data }] = useLazyQuery(
    FETCH_VALIDATOR_BY_ADDRESS,
    {
      variables: {
        address: validatorAddress,
      },
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    fetchValidatorInfo();
  }, [validatorAddress]);

  useEffect(() => {
    console.log(data?.validators[0]);
    console.log("VALIDATORDATA");
    data?.validators && setValidatorsData(data.validators[0]);
  }, [data]);

  const fetchValidatorInfo = async () => {
    setIsLoading(true);
    await getValidatorData();
    setIsLoading(false);

    setIsEpochLoading(true);
    const mode = await getMode();
    const epoch = await getEpoch();
    const dynasty = await getDynasty();
    setIsEpochLoading(false);
    console.log({ epoch, dynasty, mode });
    setHeaderData({ mode: epochMode[mode], epoch, dynasty });
  };

  return (
    <SimpleGrid
      columns={{ sm: 1, md: 2, xl: 4 }}
      spacing="24px"
      marginBottom={20}
    >
      {/* MiniStatistics Card */}
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Address
              </StatLabel>
              <Flex>
                {validatorAddress ? (
                  <Address
                    address={validatorAddress}
                    ellipsis={true}
                    allowRedirection={false}
                  />
                ) : (
                  <Skeleton
                    height="30px"
                    borderRadius={"5px"}
                    width="200px"
                  ></Skeleton>
                )}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
      {/* MiniStatistics Card */}
      <Card minH="83px">
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Stake
              </StatLabel>
              <Flex>
                {isLoading ? (
                  <Skeleton
                    height="30px"
                    borderRadius={"5px"}
                    width="200px"
                  ></Skeleton>
                ) : validatorsData?.stake ? (
                  <StatNumber fontSize="lg" color="#fff">
                    <NumberWithTooltip value={validatorsData.stake / 1e18} />
                  </StatNumber>
                ) : (
                  "-"
                )}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
      {/* MiniStatistics Card */}
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat>
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Active
              </StatLabel>
              <Flex>
                {validatorsData?.isChurnedOut ? (
                  <CloseIcon
                    color="red"
                    marginTop="10px"
                    width="1.4em"
                    height="1.4em"
                  />
                ) : (
                  <CheckCircleIcon
                    color="green"
                    marginTop="10px"
                    width="1.4em"
                    height="1.4em"
                  />
                )}
              </Flex>
            </Stat>
            <Spacer />
          </Flex>
        </CardBody>
      </Card>
      {/* MiniStatistics Card */}
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Commission
              </StatLabel>
              <Flex>
                <StatNumber fontSize="lg" color="#fff">
                  {validatorsData?.commission}%
                </StatNumber>
              </Flex>
            </Stat>
            <Stat me="auto">
              <Flex>
                {validatorsData && (
                  <ContractActions
                    signerAddressData={validatorsData}
                    validatorId={validatorsData?.validatorId}
                    delegationAccepted={validatorsData?.delegationAccepted}
                  />
                )}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Claimable Rewards
              </StatLabel>
              <Flex>
                {isLoading ? (
                  <Skeleton
                    height="30px"
                    borderRadius={"5px"}
                    width="200px"
                  ></Skeleton>
                ) : validatorsData?.claimableValidatorRewards ? (
                  <StatNumber fontSize="lg" color="#fff">
                    <NumberWithTooltip
                      value={validatorsData.claimableValidatorRewards / 1e18}
                    />
                  </StatNumber>
                ) : (
                  "-"
                )}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Current Epoch
              </StatLabel>
              <Flex>
                {isEpochLoading ? (
                  <Skeleton
                    height="30px"
                    borderRadius={"5px"}
                    width="60px"
                  ></Skeleton>
                ) : headerData?.epoch ? (
                  <StatNumber fontSize="lg" color="#fff">
                    {headerData?.epoch}
                  </StatNumber>
                ) : (
                  "-"
                )}
              </Flex>
            </Stat>
          </Flex>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Current Dynasty
              </StatLabel>
              <Flex>
                {isEpochLoading ? (
                  <Skeleton
                    height="30px"
                    borderRadius={"5px"}
                    width="60px"
                  ></Skeleton>
                ) : headerData?.dynasty ? (
                  <StatNumber fontSize="lg" color="#fff">
                    {headerData?.dynasty}
                  </StatNumber>
                ) : (
                  "-"
                )}
                {/* {headerData?.dynasty ? (
                  <StatNumber fontSize="lg" color="#fff">
                    {headerData?.dynasty}
                  </StatNumber>
                ) : (
                  <Skeleton
                    height="30px"
                    borderRadius={"5px"}
                    width="60px"
                  ></Skeleton>
                )} */}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
    </SimpleGrid>
  );
};

export default Header;
