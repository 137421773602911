import { useEffect } from "react";

// Chakra imports
import {
  Button,
  Table,
  TableCaption,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import { useLazyQuery } from "@apollo/client";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BlocksRow from "components/Tables/BlocksRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FETCH_FINALIZED_BLOCKS } from "utils/queries";

const RecentBlocksTable = () => {
  const NUM_TRANSACTIONS = 12;
  const navigate = useNavigate();
  const [blocksData, setBlocksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [getAllBlocks, { data: allBlocks, loading }] = useLazyQuery(
    FETCH_FINALIZED_BLOCKS,
    {
      variables: {
        orderBy: `timestamp`,
        orderDirection: `desc`,
        first: NUM_TRANSACTIONS,
        skip: skip,
      },
      pollInterval: 1000 * 100,
    }
  );

  useEffect(() => {
    fetchAllBlocks();
    return () => {};
  }, []);

  useEffect(() => {
    if (allBlocks?.finalizeBlockEvents) {
      if (allBlocks.finalizeBlockEvents.length < NUM_TRANSACTIONS) {
        setHasMore(false);
      }
      setBlocksData((blocksData) => [
        ...blocksData,
        ...allBlocks.finalizeBlockEvents,
      ]);
    }
  }, [allBlocks]);

  const fetchAllBlocks = async () => {
    setIsLoading(true);
    await getAllBlocks();
    setIsLoading(false);
  };

  return (
    <Card
      overflowX={{ sm: "scroll", xl: "hidden" }}
      pb="0px"
      className="scroll-content"
    >
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="lg" color="#fff" fontWeight="bold">
          Recent Finalized Blocks
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color="#fff">
          <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
            <Button
              onClick={() => navigate("/blocks")}
              isLoading={loading}
              isDisabled={!hasMore}
            >
              Load More
            </Button>
          </TableCaption>
          <Thead>
            <Tr my=".8rem" ps="0px" color="gray.400">
              <Th
                ps="0px"
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Validator
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Timestamp
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Message Data
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Block Winner ID
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Transaction Hash
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Epoch
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Dynasty
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Signer Address
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <SkeletonTable rowCount={10} />
            ) : blocksData.length === 0 ? (
              <BlocksRow row={blocksData} lastItem={true} />
            ) : (
              blocksData.map((row, index, arr) => {
                return (
                  <BlocksRow
                    key={index}
                    row={row}
                    lastItem={index === arr.length - 1}
                  />
                );
              })
            )}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default RecentBlocksTable;
