import { Box, Code, Flex, Grid, Text } from "@chakra-ui/react";
import CardBody from "components/Card/CardBody.js";
import Address from "components/Address/Address";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { BiSolidUpArrowCircle } from "react-icons/bi";
import { getTimeFromNow } from "utils";

const Header = ({ blockDetails = [], decodedBlockDetails = {} }) => {
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "3fr 1fr" }}
        gap="24px"
      >
        <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
          <CardHeader p="12px 0px 28px 0px">
            <Flex direction="column">
              <Text fontSize="lg" color="#fff" fontWeight="bold" pb="8px">
                Block {blockDetails[0]?.epoch}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction="column" lineHeight="21px">
              <Flex flex="1" gap="4" mb={8}>
                <Text w={{ md: "14rem" }} textAlign="left" fontWeight="600">
                  Dynasty:
                </Text>
                <Box fontWeight="600">{blockDetails[0]?.dynasty}</Box>
              </Flex>
              <Flex flex="1" gap="4" mb={8} alignItems="center">
                <Text w={{ md: "14rem" }} textAlign="left" fontWeight="600">
                  Proposer:
                </Text>
                <Box>
                  <Address address={blockDetails[0]?.sender} ellipsis={false} />
                </Box>
              </Flex>
              <Flex flex="1" gap="4" mb={8} alignItems="center">
                <Text w={{ md: "14rem" }} textAlign="left" fontWeight="600">
                  Signer Address:
                </Text>
                <Box>
                  <Address
                    address={blockDetails[0]?.signerAddress}
                    ellipsis={false}
                    allowRedirection={false}
                  />
                </Box>
              </Flex>
              <Flex flex="1" gap="4" mb={8} alignItems="center">
                <Text w={{ md: "14rem" }} textAlign="left" fontWeight="600">
                  Message Data:
                </Text>
                <Box className="cursor-pointer" mr="2">
                  <BiSolidUpArrowCircle onClick={() => {}} />
                </Box>
                <Code className="code-ellipsis-header ml-2">
                  {blockDetails[0]?.messageData}
                </Code>
                {/* <Button onClick={() => {}} padding={0} colorScheme="gray" ml="4px">
                            {blockDetails[0]?.messageData ? <IoCheckmarkDoneSharp /> : <IoCopyOutline />}
                        </Button> */}
              </Flex>
              <Flex flex="1" gap="4" mb={8} alignItems="center">
                <Text w={{ md: "14rem" }} textAlign="left" fontWeight="600">
                  Signature:
                </Text>
                <Code className="code-ellipsis-header ml-2">
                  {blockDetails[0]?.signature}
                </Code>
                {/* <Button onClick={() => {}} padding={0} colorScheme="gray" ml="4px">
                            {blockDetails[0]?.signature ? <IoCheckmarkDoneSharp /> : <IoCopyOutline />}
                        </Button> */}
              </Flex>
              <Flex flex="1" gap="4" mb={8}>
                <Text w={{ md: "14rem" }} textAlign="left" fontWeight="600">
                  Timestamp:
                </Text>
                <Box fontWeight="600">
                  {getTimeFromNow(blockDetails[0]?.timestamp)}
                </Box>
              </Flex>
              <Flex flex="1" gap="4" mb={8}>
                <Text w={{ md: "14rem" }} textAlign="left" fontWeight="600">
                  Number of RequestIds:
                </Text>
                <Box fontWeight="600">
                  {decodedBlockDetails?.requestIds?.length}
                </Box>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
    </Flex>
  );
};

export default Header;
