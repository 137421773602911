import { Code, Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import Address from "components/Address/Address";
import { Link } from "react-router-dom";
import { getTimeFromNow } from "utils";

const CommitEventRow = ({ committedEvent }) => {
  const {
    epoch,
    dynasty,
    action,
    sender,
    signerAddress,
    transactionHash,
    timestamp,
    validatorId,
    valueHash,
  } = committedEvent;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          {sender ? <Address address={sender} /> : "-"}
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Flex direction="column">
          <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
            {epoch ?? "-"}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {dynasty ?? "-"}
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          {transactionHash ? (
            <Link to={`/transactions/${transactionHash}`}>
              {transactionHash}
            </Link>
          ) : (
            "-"
          )}
        </Text>
      </Td>

      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {timestamp ? getTimeFromNow(timestamp) : "-"}
        </Text>
      </Td>

      <Td borderBottomColor="#56577A">
        {valueHash ? (
          <Code className="code-ellipsis ml-2">{valueHash}</Code>
        ) : (
          "-"
        )}
      </Td>
    </Tr>
  );
};

export default CommitEventRow;
