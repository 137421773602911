/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect } from "react";

// Chakra imports
import {
  Button,
  Flex,
  Table,
  TableCaption,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import { useLazyQuery } from "@apollo/client";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BlocksRow from "components/Tables/BlocksRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FETCH_FINALIZED_BLOCKS } from "utils/queries";

function Blocks() {
  const NUM_TRANSACTIONS = 50;
  const [blocksData, setBlocksData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { validatorAddress } = useParams();
  const [getAllBlocks, { data: allBlocks, loading }] = useLazyQuery(
    FETCH_FINALIZED_BLOCKS,
    {
      variables: {
        orderBy: `timestamp`,
        orderDirection: `desc`,
        first: NUM_TRANSACTIONS,
        skip: skip,
      },
      pollInterval: 1000 * 100,
    }
  );

  useEffect(() => {
    fetchAllBlocks();
    return () => {};
  }, []);

  useEffect(() => {
    if (allBlocks?.finalizeBlockEvents) {
      if (allBlocks.finalizeBlockEvents.length < NUM_TRANSACTIONS) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setBlocksData((blocksData) => [
        ...blocksData,
        ...allBlocks.finalizeBlockEvents,
      ]);
    }
  }, [allBlocks]);

  const fetchAllBlocks = async () => {
    setIsLoading(true);
    await getAllBlocks();
    setIsLoading(false);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            All Finalized Blocks
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={() => setSkip(skip + NUM_TRANSACTIONS)}
                isLoading={loading}
                isDisabled={!hasMore}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Validator
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Timestamp
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Message Data
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Block Winner ID
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Transaction Hash
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Dynasty
                </Th>
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Signer Address
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && blocksData.length === 0 ? (
                <SkeletonTable rowCount={10} />
              ) : blocksData.length === 0 ? (
                <BlocksRow row={blocksData} lastItem={true} />
              ) : (
                blocksData.length > 0 &&
                blocksData.map((row, index, arr) => {
                  return (
                    <BlocksRow
                      row={row}
                      key={index}
                      lastItem={index === arr.length - 1}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Blocks;
