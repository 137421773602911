/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Chakra imports
import { Flex, Grid } from "@chakra-ui/react";
// Styles for the circular progressbar
// Custom components
// Icons

import ActiveSetTable from "components/Tables/ActiveSetTable";
import LastFinalisedBlockTable from "components/Tables/LastFinalisedBlockTable";
import RecentBlocksTable from "components/Tables/RecentBlocksTable";

// Data

import Header from "./Header";

export default function Dashboard() {
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Header />
      <Grid templateColumns={{ sm: "1fr", md: "2fr", lg: "2fr" }} gap="24px">
        <LastFinalisedBlockTable />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "1.5fr 1fr", lg: "1.5fr 1fr" }}
        gap="24px"
      >
        <RecentBlocksTable />
        <ActiveSetTable />
      </Grid>
    </Flex>
  );
}
