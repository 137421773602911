import {
  Flex,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";

// Styles for the circular progressbar
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Icons

import { useEffect, useState } from "react";
import { epochMode } from "utils/constants";
import { getDynasty, getEpoch, getMode } from "utils/contracts";
import Address from "components/Address/Address";
import { getSignerAddressPerDynasty } from "utils/contracts";
// Data

const Header = () => {
  const [headerData, setHeaderData] = useState({
    mode: null,
    epoch: null,
    dynasty: null,
    signerAddress: null,
  });

  useEffect(() => {
    getHeaderData();
  }, []);

  useEffect(() => {
    console.log(headerData.mode);
  }, [headerData]);

  const getHeaderData = async () => {
    const mode = await getMode();
    const epoch = await getEpoch();
    const dynasty = await getDynasty();
    const signerAddress = await getSignerAddressPerDynasty(dynasty);
    setHeaderData({ mode: epochMode[mode], epoch, dynasty, signerAddress });
  };

  return (
    <SimpleGrid
      columns={{ sm: 1, md: 2, xl: 4 }}
      spacing="24px"
      marginBottom="4rem"
    >
      {/* MiniStatistics Card */}
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Current Signer Address
              </StatLabel>
              <Flex>
                {headerData.signerAddress === null ? (
                  <Skeleton height="30px" borderRadius={"5px"} width="200px"></Skeleton>
                ) : (
                  <Address address={headerData.signerAddress} />
                )}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
      {/* MiniStatistics Card */}
      <Card minH="83px">
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Epoch
              </StatLabel>
              <Flex>
                {headerData.epoch == null ? (
                  <Skeleton height="30px" borderRadius={"5px"} width="200px"></Skeleton>
                ) : (
                  <StatNumber fontSize="lg" color="#fff" fontWeight="bold">
                    {headerData.epoch}
                  </StatNumber>
                )}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
      {/* MiniStatistics Card */}
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat>
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Dynasty
              </StatLabel>
              <Flex>
                {headerData.dynasty == null ? (
                  <Skeleton height="30px" borderRadius={"5px"} width="200px"></Skeleton>
                ) : (
                  <StatNumber fontSize="lg" color="#fff" fontWeight="bold">
                    {headerData.dynasty}
                  </StatNumber>
                )}
              </Flex>
            </Stat>
            <Spacer />
          </Flex>
        </CardBody>
      </Card>
      {/* MiniStatistics Card */}
      <Card>
        <CardBody>
          <Flex flexDirection="row" align="center" justify="center" w="100%">
            <Stat me="auto">
              <StatLabel
                fontSize="sm"
                color="gray.400"
                fontWeight="bold"
                pb="2px"
              >
                Mode
              </StatLabel>
              <Flex>
                {headerData.mode == null ? (
                  <Skeleton height="30px" borderRadius={"5px"} width="200px"></Skeleton>
                ) : (
                  <StatNumber fontSize="lg" color="#fff" fontWeight="bold">
                    {headerData.mode}
                  </StatNumber>
                )}
              </Flex>
            </Stat>
          </Flex>
        </CardBody>
      </Card>
    </SimpleGrid>
  );
};

export default Header;
