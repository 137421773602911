import { useLazyQuery } from "@apollo/client";
import { CopyIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  List,
  ListItem,
  Stack,
  Tag,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import TransactionsSkeletonData from "components/Tables/TransactionsSkeletonData";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatTimestamp } from "utils";
import { getBlockExplorerUrl } from "utils/constants";
import { FETCH_TRANSACTION_BY_HASH } from "utils/queries";

const TransactionRowItem = ({ title, options, ellipsis = false }) => {
  let value = options;
  const toast = useToast();

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast({
        title: "Text copied to clipboard",
        status: "success",
        duration: 1000,
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  if (title === "timestamp") value = formatTimestamp(value);
  return (
    <Stack
      p={2}
      py={2}
      justifyContent={{
        base: "flex-start",
        md: "space-between",
        lg: "space-between",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      alignItems={{ md: "center" }}
    >
      <Heading size={"md"} style={{ flex: 1 }}>
        {<Tag>{title}</Tag>}
      </Heading>
      <List spacing={3} style={{ flex: 3 }}>
        <ListItem className={ellipsis ? "custom-ellipsis" : ""} color="#fff">
          {title === "messageData" ||
          title === "signature" ||
          title === "transactionHash" ||
          title === "message" ? (
            <CopyIcon
              onClick={() => copyToClipboard(value)}
              cursor="pointer"
              mr={2}
            />
          ) : (
            ""
          )}
          {title === "transactionHash" ? (
            <Link href={`${getBlockExplorerUrl()}tx/${value}`} isExternal>
              {value}
            </Link>
          ) : title === "address" || title === "sender" ? (
            <Link href={`${getBlockExplorerUrl()}address/${value}/`} isExternal>
              {value}
            </Link>
          ) : (
            `${value}`
          )}
        </ListItem>
      </List>
    </Stack>
  );
};

const TransactionDetails = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { transactionHash } = useParams();

  const [getTransactions, { data }] = useLazyQuery(FETCH_TRANSACTION_BY_HASH, {
    variables: {
      hash: transactionHash,
    },
  });

  const fetchTransactionInfo = async () => {
    setIsLoading(true);
    await getTransactions();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTransactionInfo();
  }, [transactionHash]);

  useEffect(() => {
    data?.transactions && setTransactionData(data.transactions);
  }, [data]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Stack spacing={1} width={"100%"} direction={"column"}>
        {isLoading ? (
          <TransactionsSkeletonData />
        ) : transactionData.length === 0 ? (
          <Card>
            <Heading size="md" color="#fff">
              No transaction found with hash {transactionHash}
            </Heading>
          </Card>
        ) : (
          transactionData.map((item, index) => {
            return (
              <Card key={index}>
                {Object.entries(item).map(([key, value], subIndex) => {
                  if (
                    typeof value !== "object" &&
                    key !== "__typename" &&
                    key !== "id"
                  ) {
                    return (
                      <React.Fragment key={subIndex}>
                        <TransactionRowItem title={key} options={value} />
                        <Divider />
                      </React.Fragment>
                    );
                  } else if (typeof value === "object" && !!value) {
                    return (
                      <Accordion allowToggle key={subIndex}>
                        <AccordionItem border="none">
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left" color="#fff">
                                {value.__typename}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {Object.entries(value).map(
                              ([innerKey, innerValue], innerIndex) => (
                                <React.Fragment key={innerIndex}>
                                  <TransactionRowItem
                                    title={innerKey}
                                    options={innerValue}
                                    ellipsis={
                                      innerKey === "signature" ||
                                      innerKey === "messageData" ||
                                      innerKey === "message"
                                    }
                                  />
                                  <Divider />
                                </React.Fragment>
                              )
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    );
                  }
                  return null;
                })}
              </Card>
            );
          })
        )}
      </Stack>
    </Flex>
  );
};

export default TransactionDetails;
