import {
  Badge,
  Flex,
  Skeleton,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

const SkeletonTable = ({ rowCount }) => {
  const rows = [];

  for (let i = 0; i < rowCount; i++) {
    rows.push(
      <Tr key={i}>
        <Td minWidth={{ sm: "250px" }} ps="0px" borderBottomColor="#56577A">
          <Skeleton height="30px" borderRadius={"5px"}></Skeleton>
        </Td>

        <Td borderBottomColor="#56577A">
          <Skeleton height="30px" borderRadius={"5px"}></Skeleton>
        </Td>

        <Td borderBottomColor="#56577A">
          <Skeleton height="30px" borderRadius={"5px"}></Skeleton>
        </Td>

        <Td borderBottomColor="#56577A">
          <Skeleton height="30px" borderRadius={"5px"}></Skeleton>
        </Td>

        <Td borderBottomColor="#56577A" minW="150px">
          <Skeleton height="30px" borderRadius={"5px"}></Skeleton>
        </Td>
        <Td borderBottomColor="#56577A">
          <Skeleton height="30px" borderRadius={"5px"}></Skeleton>
        </Td>
        <Td borderBottomColor="#56577A">
          <Skeleton height="30px" borderRadius={"5px"}></Skeleton>
        </Td>
      </Tr>
    );
  }

  return rows;
};

export default SkeletonTable;
