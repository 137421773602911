import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Code,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  StackDivider,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Tag,
  Text,
  useMediaQuery,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import Blockies from "react-blockies";
import useTokenBalance from "../../hooks/useTokenBalance";
import { ethers } from "ethers";
import {
  approveDToken,
  getdTokenBalance,
  signalWithdraw,
} from "../../utils/contracts";
import { useAccount } from "wagmi";

const SignalWithdrawDrawer = ({
  isOpen,
  onClose,
  validatorId,
  validatorAddress,
  toggleDrawer,
  signerAddressData,
}) => {
  const [undelegateAmount, setUndelegateAmount] = useState(0);
  const [dTokenAmount, setdTokenAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const firstField = useRef();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const steps = [
    { title: "Approve", description: "Approve tokens" },
    { title: "Signal Withdraw", description: "Signal Withdraw dTokens" },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    address && fetchDelegatedAmount();
  }, [address]);

  const fetchDelegatedAmount = async () => {
    const tokenBalance = await getdTokenBalance(
      signerAddressData?.dToken,
      address
    );
    setdTokenAmount(Number(tokenBalance));
  };
  const { balance } = useTokenBalance();
  const toast = useToast();
  const formattedBalance = ethers.utils.formatEther(balance);

  const onAmountChange = (e) => setUndelegateAmount(e.target.value);

  const resetStep = () => {
    toggleDrawer();
    setActiveStep(0);
    setUndelegateAmount(0);
  };

  const triggerToast = (title, status = "success", description = null) => {
    toast({
      title,
      description,
      status,
      isClosable: true,
      duration: 5000,
    });
  };

  const handleRPCError = (error) => {
    return <Code colorScheme="black">{error.message}</Code>;
  };

  const approveTokens = async () => {
    setIsLoading(true);
    console.log(undelegateAmount);
    const { success, txHash, error } = await approveDToken(
      undelegateAmount,
      signerAddressData?.dToken
    );
    console.log({
      success,
      txHash,
    });
    if (success) {
      setActiveStep(activeStep + 1);
      triggerToast("Approve transaction successful", "success", null);
    } else {
      triggerToast(
        "Approve transaction failed",
        "error",
        handleRPCError(error)
      );
    }
    setIsLoading(false);
  };

  const signalWithdrawTokens = async () => {
    setIsLoading(true);
    console.log({ undelegateAmount, validatorId });

    const { success, txHash, error } = await signalWithdraw(
      validatorId,
      undelegateAmount
    );
    console.log({
      txHash,
    });
    if (success) {
      triggerToast("Signal Withdraw transaction successful", "success", null);
      toggleDrawer();
      setActiveStep(activeStep + 1);
    } else {
      triggerToast(
        "Signal Withdraw transaction failed",
        "error",
        error?.message && handleRPCError(error)
      );
    }
    setIsLoading(false);
  };

  const onClickHandler = async () => {
    switch (activeStep) {
      case 0:
        approveTokens();
        break;
      case 1:
        signalWithdrawTokens();
        break;
    }
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        initialFocusRef={firstField}
        onClose={onClose}
        placement={isMobile ? "bottom" : "right"}
      >
        <DrawerOverlay />
        <DrawerContent bg="linear-gradient(111.84deg, rgba(0, 0, 0, 0.94) 59.3%, rgba(26, 31, 55, 0) 100%)">
          <DrawerHeader borderBottomWidth="1px">
            <Tag>Signal Withdraw</Tag>
            <Flex>
              <Blockies seed={validatorAddress} className="indenticon" />
              <Text>{validatorAddress.slice(0, 20)}</Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing="24px">
              <Box>
                <Stack
                  divider={<StackDivider />}
                  spacing="4"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      Balance
                    </Heading>
                    <Text pt="2" fontSize="sm">
                      {formattedBalance}
                    </Text>
                  </Box>
                  <Box>
                    <Heading
                      size="xs"
                      textTransform="uppercase"
                      textAlign="end"
                    >
                      dToken Balance
                    </Heading>
                    <Text pt="2" fontSize="sm" textAlign="end">
                      {dTokenAmount.toFixed(5)}
                    </Text>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <FormLabel htmlFor="amount">Signal Withdraw Amount</FormLabel>
                <InputGroup cursor="pointer">
                  <Input
                    type="number"
                    id="amount"
                    placeholder="Enter an amount"
                    onChange={onAmountChange}
                    disabled={activeStep == 1}
                    value={undelegateAmount}
                  />
                  <InputRightAddon
                    onClick={() =>
                      activeStep == 0 && setUndelegateAmount(dTokenAmount)
                    }
                  >
                    MAX
                  </InputRightAddon>
                </InputGroup>
              </Box>
              <Box>
                <Stepper index={activeStep}>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>

                      <Box flexShrink="0">
                        <StepTitle fontSize="0.7rem">{step.title}</StepTitle>
                      </Box>

                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={() => resetStep()}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="blue"
              onClick={() => onClickHandler()}
              isDisabled={isLoading || undelegateAmount <= 0}
            >
              {steps[activeStep]?.title ? steps[activeStep].title : "Approve"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SignalWithdrawDrawer;
