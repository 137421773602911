import React, { useState } from "react";
import { SearchIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";
import { isAddress } from "ethers/lib/utils";
import { useLazyQuery } from "@apollo/client";
import {
  FETCH_ATTESTED_SIGNERS_BY_ADDRESS,
  FETCH_ATTESTED_SIGNERS_BY_HASH,
  FETCH_FINALIZED_BLOCKS_BY_ADDRESS,
  FETCH_FINALIZED_BLOCKS_BY_HASH,
  FETCH_TRANSACTION_BY_ADDRESS,
  FETCH_TRANSACTION_BY_ADDRESS_ACTION,
  FETCH_TRANSACTION_BY_HASH,
} from "utils/queries";
import { isEmpty } from "lodash";
import { isMobile } from "react-device-detect";
import { isTxnHash, isRequestId } from "utils";

const Searchbar = ({
  setSearchBarData,
  resetData,
  parent,
  selectedActions = [],
  setFilterAddress,
}) => {
  const [inputString, setInputString] = useState("");
  const [inputType, setInputType] = useState();
  const [hasError, setHasError] = useState(false);
  const [getTransactionByHash] = useLazyQuery(FETCH_TRANSACTION_BY_HASH);
  const [getTransactionByAddress] = useLazyQuery(FETCH_TRANSACTION_BY_ADDRESS);
  const [getAttestedEventsByHash] = useLazyQuery(
    FETCH_ATTESTED_SIGNERS_BY_HASH
  );
  const [getAttestedEventsByAddress] = useLazyQuery(
    FETCH_ATTESTED_SIGNERS_BY_ADDRESS
  );
  const [getTransactionByAddressAndFilter] = useLazyQuery(
    FETCH_TRANSACTION_BY_ADDRESS_ACTION
  );
  const [getFinalizedBlocksByHash] = useLazyQuery(
    FETCH_FINALIZED_BLOCKS_BY_HASH
  );
  const [getFinalizedBlocksByAddress] = useLazyQuery(
    FETCH_FINALIZED_BLOCKS_BY_ADDRESS
  );

  const validateInput = (input) => {
    const type = isAddress(input)
      ? "address"
      : isTxnHash(input)
      ? "hash"
      : isRequestId(input)
      ? "requestId"
      : "invalid";
    setInputType(type);
    return type;
  };

  const handleOnChange = (input) => {
    setInputString(input);
    setHasError(false);
    const type = validateInput(input);
    if (!isEmpty(input) && type !== "invalid") {
      fetchSuggestions(type, input);
    } else if (!isEmpty(input) && type === "invalid") {
      setHasError(true);
    } else {
      handleClearInput();
    }
  };

  const fetchSuggestions = async (type, newValue) => {
    if (parent === "transactions") {
      if (type === "hash") {
        getTransactionByHash({
          variables: {
            hash: newValue,
          },
        })
          .then((response) => {
            const { data } = response;
            if (data?.transactions.length > 0) {
              setSearchBarData(data.transactions);
            } else setSearchBarData([]);
          })
          .catch(() => {
            console.log("Error fetching data");
          });
      } else if (type === "address") {
        setFilterAddress(newValue);
      }
    } else if (parent === "attestedSigners") {
      if (type === "hash") {
        getAttestedEventsByHash({
          variables: {
            hash: newValue,
          },
        })
          .then((response) => {
            const { data } = response;
            if (data?.attestedEvents.length > 0) {
              setSearchBarData(data.attestedEvents);
            } else setSearchBarData([]);
          })
          .catch(() => {
            console.log("Error fetching data");
          });
      } else if (type === "address") {
        getAttestedEventsByAddress({
          variables: {
            address: newValue,
            orderBy: "timestamp",
            orderDirection: "desc",
          },
        })
          .then((response) => {
            const { data } = response;
            if (data?.attestedEvents.length > 0) {
              setSearchBarData(data.attestedEvents);
            } else setSearchBarData([]);
          })
          .catch(() => {
            console.log("Error fetching data");
          });
      }
    } else if (parent === "blocks") {
      if (type === "hash") {
        getFinalizedBlocksByHash({
          variables: {
            hash: newValue,
          },
        })
          .then((response) => {
            const { data } = response;
            if (data?.finalizeBlockEvents.length > 0) {
              setSearchBarData(data.finalizeBlockEvents);
            } else setSearchBarData([]);
          })
          .catch(() => {
            console.log("Error fetching data");
          });
      } else if (type === "address") {
        getFinalizedBlocksByAddress({
          variables: {
            address: newValue,
            orderBy: "timestamp",
            orderDirection: "desc",
          },
        })
          .then((response) => {
            const { data } = response;
            if (data?.finalizeBlockEvents.length > 0) {
              setSearchBarData(data.finalizeBlockEvents);
            } else setSearchBarData([]);
          })
          .catch(() => {
            console.log("Error fetching data");
          });
      }
    } else if (parent === "requests") {
      setFilterAddress(newValue);
    }
  };

  const handleClearInput = () => {
    setInputString("");
    setHasError(false);
    setFilterAddress("");
    // resetData();
  };

  return (
    <Box
      minW="200px"
      width={isMobile ? "100%" : "40%"}
      mb={isMobile ? "24px" : ""}
    >
      <InputGroup
        width="auto"
        borderRadius="4px"
        variant="filled"
        size="md"
        className={hasError ? "search-focus-error" : "search-border"}
      >
        <InputLeftAddon borderLeftRadius="10px">
          <SearchIcon />
        </InputLeftAddon>
        <Input
          placeholder={
            parent === "requests" ? "Search by requestId" : "Search by address"
          }
          borderLeftRadius="10px"
          onChange={(e) => handleOnChange(e.target.value)}
          value={inputString}
          px={2}
          _focusVisible={{ border: "2px solid #fff", outline: "none" }}
        />
        <InputRightAddon
          onClick={() => handleClearInput()}
          borderRightRadius="10px"
          cursor="pointer"
        >
          <SmallCloseIcon />
        </InputRightAddon>
      </InputGroup>
    </Box>
  );
};

export default Searchbar;
