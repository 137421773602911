import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import _debounce from "lodash/debounce";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import Blockies from "react-blockies";
import { isAddress } from "ethers/lib/utils";
import {
  Box,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { isTxnHash } from "utils";
import {
  FETCH_SEARCH_BY_ADDRESS,
  FETCH_TRANSACTION_BY_HASH_SEARCH,
} from "utils/queries";

const UniversalSearchBar = () => {
  const navigate = useNavigate();
  const [inputType, setInputType] = useState();
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [getAddress] = useLazyQuery(FETCH_SEARCH_BY_ADDRESS);
  const [getTransaction] = useLazyQuery(FETCH_TRANSACTION_BY_HASH_SEARCH);
  const currentPath = window?.location?.pathname;

  let searchIcon = "white";
  let inputBg = "#0F1535";
  let mainText = "gray.400";

  useEffect(() => {
    if (inputType === "invalid") {
      onSuggestionsClearRequested();
    }
  }, [inputType]);

  const debounceFn = _debounce(
    (type, newValue) => fetchSuggestions(type, newValue),
    800
  );

  const validateInput = (input) => {
    const type = isAddress(input)
      ? "address"
      : isTxnHash(input)
      ? "hash"
      : "invalid";
    setInputType(type);
    return type;
  };

  const onChange = (event, { newValue }) => {
    setInputValue(newValue);
    const type = validateInput(newValue);
    debounceFn(type, newValue);
  };

  const redirect = (inputValue) => {
    if (inputType === "address") {
      navigate(`/validators/${inputValue}`);
    } else if (inputType === "hash") {
      navigate(`/transactions/${inputValue}`);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const type = validateInput(value);
    setInputType(type);
  };

  const fetchSuggestions = (type, input) => {
    if (type === "address") {
      //Fetch Address
      getAddress({
        variables: {
          address: input,
        },
      })
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (data?.validators.length > 0) {
            const suggest = [{ value: input }];
            setSuggestions(suggest);
          }
        })
        .catch(() => {
          setSuggestions([]);
        });
    } else if (type === "hash") {
      //Fetch txn hash
      getTransaction({
        variables: {
          hash: input,
        },
      })
        .then((response) => {
          const { data } = response;
          if (data?.transactions.length > 0) {
            const suggest = [{ value: input }];
            setSuggestions(suggest);
          }
        })
        .catch(() => {
          setSuggestions([]);
        });
    } else onSuggestionsClearRequested();
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion.
  const getSuggestionValue = (suggestion) => suggestion?.value || "";

  const selectedVal = (event, { suggestion }) => {
    setInputValue(suggestion.value);
    redirect(suggestion.value);
  };

  const onKeyDown = (e) => {
    //Handle enter pressed
    if (e.keyCode === 13) {
      redirect(e.target.value);
    }
  };

  const inputProps = {
    placeholder: "Search by Address / Txn Hash",
    value: inputValue,
    onChange: onChange,
    className: "h-100",
    onKeyDown: onKeyDown,
  };

  const renderSuggestion = (suggestion) => (
    <Box className={`suggestion-content`}>
      {inputType === "hash" ? (
        <Box className="autosuggest-blockies">
          <Blockies seed={suggestion.value} size={10} scale={3} />
          <span>{suggestion.value}</span>
        </Box>
      ) : inputType === "address" ? (
        <Box className="autosuggest-blockies">
          <Blockies seed={suggestion.value} size={10} scale={3} />
          <span>{suggestion.value}</span>
        </Box>
      ) : (
        <>
          <span className={`badge badge-primary mr-1`}>{inputType}</span>
          {suggestion.value}
        </>
      )}
    </Box>
  );

  return (
    <>
      <Box className="custom-search-dropdown">
        <InputGroup
          className={
            inputType == "invalid" && inputValue.length > 0
              ? "search-focus-error"
              : "search-border"
          }
          borderRadius="4px"
          variant="filled"
          size="md"
        >
          <Autosuggest
            suggestions={suggestions || []}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={selectedVal}
            highlightFirstSuggestion={true}
            renderInputComponent={(inputProps) => (
              <>
                <InputRightElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      icon={
                        <SearchIcon
                          onClick={() => redirect(inputValue)}
                          color={searchIcon}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  fontSize="sm"
                  color={mainText}
                  placeholder="Type here..."
                  borderRadius="inherit"
                  {...inputProps}
                  paddingRight="40px"
                />
              </>
            )}
          />
        </InputGroup>
      </Box>
    </>
  );
};

export default UniversalSearchBar;
