import { Badge, Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import Address from "components/Address/Address";
import NumberWithTooltip from "components/Text/NumberWithTooltip";
import React from "react";

function TablesValidatorsRow(props) {
  const {
    address,
    stake,
    validatorId,
    lastAttestedDynasty,
    lastAttestedEpoch,
    lastFinalizedBlock,
    lastEpochChurnedOut,
    status,
    lastItem,
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td
        ps="0px"
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {validatorId ?? "-"}
        </Text>
      </Td>

      <Td
        minWidth={{ sm: "250px" }}
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            {address ? <Address address={address} /> : "-"}
          </Flex>
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Badge
          bg={status === true ? "transparent" : "green.400"}
          color={status === true ? colorStatus : "white"}
          fontSize="sm"
          p="3px 10px"
          borderRadius="8px"
          border={status == true ? "1px solid #fff" : "none"}
          fontWeight="bold"
        >
          {status == true ? "Inactive" : "Active"}
        </Badge>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {stake ? <NumberWithTooltip value={stake} /> : "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {lastFinalizedBlock ?? "-"}
        </Text>
      </Td>

      <Td
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
        minW="150px"
      >
        <Flex direction="column">
          <Text fontSize="sm" color="#fff" fontWeight="bold">
            {lastAttestedDynasty ?? "-"}
          </Text>
          {/* <Text fontSize='sm' color='gray.400' fontWeight='bold'>
              {sublastAttestedDynasty}
            </Text> */}
        </Flex>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {lastAttestedEpoch ?? "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {lastEpochChurnedOut ?? "-"}
        </Text>
      </Td>
    </Tr>
  );
}

export default TablesValidatorsRow;
