import moment from "moment";

export const getTimeFromNow = (timestamp) => {
  if (timestamp) return moment.unix(timestamp).fromNow();
};

export const formatTimestamp = (unixTimestamp) => {
  const timestamp = moment.unix(unixTimestamp);
  const localTime = timestamp.local();
  const relativeTime = localTime.fromNow();
  const formattedTime = localTime.format("MMM-DD-YYYY hh:mm:ss A [+]UTC");
  return relativeTime + " (" + formattedTime + ")";
};

export const isTxnHash = (hash) => {
  if (/^0x([A-Fa-f0-9]{64})$/.test(hash)) return true;
  return false;
};

export const isRequestId = (requestId) => {
  const pattern = /^0x[a-fA-F0-9]{8}$/;
  return pattern.test(requestId);
};
