/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ApolloProvider, InMemoryCache, ApolloClient } from "@apollo/client";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
  rabbyWallet,
  metaMaskWallet,
  rainbowWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import AdminLayout from "layouts/Admin.js";
import "@rainbow-me/rainbowkit/styles.css";
import "./App.css";
import "./App.scss";
import { metalayerHoleskyNetwork } from "utils/constants";
import { ChakraProvider } from "@chakra-ui/react";
import themeAdmin from "theme/themeAdmin";

const { chains, provider } = configureChains(
  [metalayerHoleskyNetwork],
  [
    jsonRpcProvider({
      rpc: () => ({ http: metalayerHoleskyNetwork.rpcUrls.default.http[0] }),
    }),
    publicProvider(),
  ],
  { pollingInterval: 10_000 }
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      rainbowWallet({ projectId: "Razorscan", chains }),
      metaMaskWallet({ projectId: "Razorscan", chains }),
      rabbyWallet({ chains }),
      coinbaseWallet({ chains, appName: "RazorScan" }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <ChakraProvider theme={themeAdmin}>
    <ApolloProvider client={client}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} modalSize="compact">
          <BrowserRouter>
            <Routes>
              <Route path={`/*`} element={<AdminLayout />} />

              {/* redirect */}
              {/* <Route path="/" element={<Navigate to="/new-path" replace />} /> */}
            </Routes>
          </BrowserRouter>
        </RainbowKitProvider>
      </WagmiConfig>
    </ApolloProvider>
  </ChakraProvider>
);
