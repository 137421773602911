/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// Chakra imports
import {
  Button,
  Flex,
  Table,
  TableCaption,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

// Table Components

// Data

// Icons
import { useLazyQuery } from "@apollo/client";
import AttestationsRow from "components/Tables/AttestationsRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { useParams } from "react-router-dom";
import {
  FETCH_ATTESTED_SIGNERS,
  FETCH_ATTESTED_SIGNERS_BY_ADDRESS,
} from "utils/queries";

const NUM_TRANSACTIONS = 50;

const Attestations = () => {
  const [attestations, setAttestations] = useState([]);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { validatorAddress } = useParams();
  const [getAllAttestedSigners, { data: allSigners, loading }] = useLazyQuery(
    FETCH_ATTESTED_SIGNERS,
    {
      variables: {
        orderBy: `timestamp`,
        orderDirection: `desc`,
        first: NUM_TRANSACTIONS,
        skip: skip,
      },
      pollInterval: 1000 * 100,
    }
  );
  const [getAttestedSigners, { data: addressSigner }] = useLazyQuery(
    FETCH_ATTESTED_SIGNERS_BY_ADDRESS,
    {
      variables: {
        orderBy: `timestamp`,
        orderDirection: `desc`,
        address: validatorAddress,
        first: NUM_TRANSACTIONS,
        skip: skip,
      },
      pollInterval: 1000 * 100,
    }
  );

  const fetchInitData = async () => {
    setIsLoading(true);
    !validatorAddress && (await getAllAttestedSigners());
    validatorAddress && (await getAttestedSigners());
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitData();
    return () => {};
  }, []);

  useEffect(() => {
    if (allSigners?.attestedEvents?.length > 0) {
      if (allSigners.attestedEvents.length < NUM_TRANSACTIONS) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setAttestations((attestations) => [
        ...attestations,
        ...allSigners.attestedEvents,
      ]);
    }
  }, [allSigners]);

  useEffect(() => {
    if (addressSigner?.attestedEvents?.length > 0) {
      if (addressSigner.attestedEvents.length < NUM_TRANSACTIONS) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setAttestations((attestations) => [
        ...attestations,
        ...addressSigner.attestedEvents,
      ]);
    }
  }, [addressSigner]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {/* Authors Table */}
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            {validatorAddress
              ? `Attestations by ${validatorAddress}`
              : `All Attestations`}
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff" flexDirection="column">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={() => setSkip(skip + NUM_TRANSACTIONS)}
                isLoading={loading}
                isDisabled={!hasMore}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Timestamp
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Dynasty
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Transaction Hash
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Sender
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Signer Address
                </Th>
                {/* <Th borderBottomColor="#56577A"></Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && attestations.length === 0 ? (
                <SkeletonTable rowCount={10} />
              ) : attestations.length === 0 ? (
                <AttestationsRow
                  attestation={attestations}
                  key={0}
                  lastItem={true}
                />
              ) : (
                attestations.map((row, index) => {
                  return (
                    <AttestationsRow
                      attestation={row}
                      key={index}
                      lastItem={index === attestations.length - 1}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default Attestations;
