import { Tooltip } from "@chakra-ui/react";

const NumberWithTooltip = ({ value, precision = 2 }) => {
  return (
    <Tooltip label={value} aria-label="number" placement="top">
      <p>{Number(value.toFixed(precision))}</p>
    </Tooltip>
  );
};

export default NumberWithTooltip;
