import { Code, Flex, Td, Text, Tr } from "@chakra-ui/react";
import Address from "components/Address/Address";
import { Link } from "react-router-dom";
import { getTimeFromNow } from "utils";

const RevealEventRow = ({ revealedEvent }) => {
  const {
    id,
    requestId,
    epoch,
    requestingChainDomain,
    target,
    timestamp,
    transactionHash,
    values,
    destinationChainDomain,
    dynasty,
    sender,
  } = revealedEvent;

  return (
    <Tr>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          {sender ? <Address address={sender} /> : "-"}
        </Text>
      </Td>
      <Td borderBottomColor="#56577A">
        <Flex direction="column">
          <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
            {epoch ?? "-"}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {dynasty ?? "-"}
        </Text>
      </Td>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          {transactionHash ? (
            <Link to={`/transactions/${transactionHash}`}>
              {transactionHash}
            </Link>
          ) : (
            "-"
          )}
        </Text>
      </Td>

      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {timestamp ? getTimeFromNow(timestamp) : "-"}
        </Text>
      </Td>

      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {requestingChainDomain ?? "-"}
        </Text>
      </Td>

      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {destinationChainDomain ?? "-"}
        </Text>
      </Td>

      <Td borderBottomColor="#56577A">
        {values ? <Code className="code-ellipsis ml-2">{values}</Code> : "-"}
      </Td>
    </Tr>
  );
};

export default RevealEventRow;
