import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Code,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Tag,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import Blockies from "react-blockies";
import useTokenBalance from "../../hooks/useTokenBalance";
import { ethers } from "ethers";
import { getdTokenBalance, withdraw } from "../../utils/contracts";
import { useAccount } from "wagmi";

const WithdrawDrawer = ({
  isOpen,
  onClose,
  validatorId,
  validatorAddress,
  toggleDrawer,
  signerAddressData,
  withdrawLock,
}) => {
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [dTokenAmount, setdTokenAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const firstField = useRef();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    fetchDelegatedAmount();
  }, []);

  useEffect(() => {
    if (withdrawLock?.amount) setWithdrawAmount(withdrawLock?.amount);
  }, [withdrawLock]);

  const fetchDelegatedAmount = async () => {
    const tokenBalance = await getdTokenBalance(
      signerAddressData?.dToken,
      address
    );
    setdTokenAmount(Number(tokenBalance));
  };
  const { balance } = useTokenBalance();
  const toast = useToast();
  const formattedBalance = ethers.utils.formatEther(balance);

  const resetStep = () => {
    toggleDrawer();
  };

  const triggerToast = (title, status = "success", description = null) => {
    toast({
      title,
      description,
      status,
      isClosable: true,
      duration: 5000,
    });
  };

  const handleRPCError = (error) => {
    return <Code colorScheme="black">{error.message}</Code>;
  };

  const withdrawTokens = async () => {
    setIsLoading(true);

    const { success, txHash, error } = await withdraw(validatorId);
    console.log({
      txHash,
    });
    if (success) {
      triggerToast("Withdraw transaction successful", "success", null);
      toggleDrawer();
    } else {
      triggerToast(
        "Withdraw transaction failed",
        "error",
        error?.message && handleRPCError(error)
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        initialFocusRef={firstField}
        onClose={onClose}
        placement={isMobile ? "bottom" : "right"}
      >
        <DrawerOverlay />
        <DrawerContent bg="linear-gradient(111.84deg, rgba(0, 0, 0, 0.94) 59.3%, rgba(26, 31, 55, 0) 100%)">
          <DrawerHeader borderBottomWidth="1px">
            <Tag>Withdraw</Tag>
            <Flex>
              <Blockies seed={validatorAddress} className="indenticon" />
              <Text>{validatorAddress.slice(0, 20)}</Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing="24px">
              <Box>
                <Stack
                  divider={<StackDivider />}
                  spacing="4"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      Amount
                    </Heading>
                    <Text pt="2" fontSize="sm">
                      {Number(ethers.utils.formatEther(withdrawAmount)).toFixed(
                        5
                      )}
                    </Text>
                  </Box>
                  <Box>
                    <Heading
                      size="xs"
                      textTransform="uppercase"
                      textAlign="end"
                    >
                      dToken Balance
                    </Heading>
                    <Text pt="2" fontSize="sm" textAlign="end">
                      {dTokenAmount.toFixed(5)}
                    </Text>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={() => resetStep()}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="blue"
              onClick={() => withdrawTokens()}
            >
              Withdraw
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default WithdrawDrawer;
